import {React} from "react";
import "./Navigation.css";
import MyButton from '../Button/Button';
// const navBtn = useState();

function Navigation() {
  const toggleOn = (target)=>{
    target.classList.toggle("on");
  }
  const removeOn = (target)=>{
    target.classList.remove("on");
  }
  return (
    <>
      <div className="nav_menu" onClick={()=>{
        toggleOn(document.querySelector(".nav_menu"))
        }}>
        <span className="nav_ham"></span>
      </div>
      <nav className="navigation">
        <ul className="nav_list">
          <li className="nav_item">
            <a href="#products" onClick={()=>{
              removeOn(document.querySelector(".nav_menu"));
            }}>Products</a>
          </li>
          <li className="nav_item">
            <a href="#solutions" onClick={()=>{
               removeOn(document.querySelector(".nav_menu"));
            }}>Solutions</a>
          </li>
          <li className="nav_item">
            <a href="#Resources" onClick={()=>{
              removeOn(document.querySelector(".nav_menu"));
            }}>Resources</a>
          </li>
          <li className="nav_item">
            <a href="#pricing" onClick={()=>{
               removeOn(document.querySelector(".nav_menu"));
            }}>Pricing</a>
          </li>
          <li>
            <MyButton text={"Login"} href={"#"} color={"yellow"}></MyButton>
          </li>
          <li>
            <MyButton text={"Try Whitepace free"} href={"#"}></MyButton>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navigation;