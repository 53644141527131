import React from "react";
import "./Kv.css";
import MyButton from '../Button/Button';
function Kv({}) {
  return (
    <section className="kv">
      <div className="kv_container content_inner">
        <div className="kv_left">
          <h1 className="main_heading u-spTextCenter">Get More Done with whitepace</h1>
          <p className="main_heading_subtitle u-spTextCenter">Project management software that enables your teams to collaborate, plan, analyze and manage everyday tasks</p>
          <div className="u-spTextCenter">
            <MyButton text={"Try Whitepace free"} href={"#"}></MyButton>
          </div>
        </div>
        <div className="kv_right">
          <img src="./img/img01.png" alt="kv" />
        </div>
      </div>
    </section>
  );
}

export default Kv;