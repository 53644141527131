import React from "react";
import { Navigation, Pagination, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// Customized ClientSlider styles
import "./PriceCard.css";
import MyButton from '../Button/Button';
function PriceCard({}) {
  return (
    <Swiper
        modules={[Navigation,Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={50}
        slidesPerView={3}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          // when window width is >= 768px
          0: {
            slidesPerView: 1,
          },
          // when window width is >= 640px
          768: {
            slidesPerView: 3,
          },
      
        }}
      >
        <SwiperSlide>
          <article className="priceCard yellow">
            <h3 className="price_title">Free</h3>
            <p className="price">$0</p>
            <p className="price_description">Capture ideas and find them quickly</p>
            <ul className="plan_item-list">
              <li>Sync unlimited devices</li>
              <li>10 GB monthly uploads</li>
              <li>200 MB max. note size</li>
              <li>Customize Home dashboard and access extra widgets</li>
              <li>Connect primary Google Calendar account</li>
              <li>Add due dates, reminders, and notifications to your tasks</li>
            </ul>
            <MyButton text={"Get Started"} href={"#"} type={"no-arrow"}></MyButton>
          </article>
        </SwiperSlide>
        <SwiperSlide>
          <article className="priceCard yellow">
            <h3 className="price_title">Personal</h3>
            <p className="price">$11.99</p>
            <p className="price_description">Keep home and family on track</p>
            <ul className="plan_item-list">
              <li>Sync unlimited devices</li>
              <li>10 GB monthly uploads</li>
              <li>200 MB max. note size</li>
              <li>Customize Home dashboard and access extra widgets</li>
              <li>Connect primary Google Calendar account</li>
              <li>Add due dates, reminders, and notifications to your tasks</li>
            </ul>
            <MyButton text={"Get Started"} href={"#"} type={"no-arrow"}></MyButton>
          </article>
        </SwiperSlide>
        <SwiperSlide>
          <article className="priceCard yellow">
            <h3 className="price_title">Organization</h3>
            <p className="price">$49.99</p>
            <p className="price_description">Capture ideas and find them quickly</p>
            <ul className="plan_item-list">
              <li>Sync unlimited devices</li>
              <li>10 GB monthly uploads</li>
              <li>200 MB max. note size</li>
              <li>Customize Home dashboard and access extra widgets</li>
              <li>Connect primary Google Calendar account</li>
              <li>Add due dates, reminders, and notifications to your tasks</li>
            </ul>
            <MyButton text={"Get Started"} href={"#"} type={"no-arrow"}></MyButton>
          </article>
        </SwiperSlide>
      </Swiper>
    // <div className='priceCard_container'>
    //   <article className="priceCard yellow">
    //     <h3 className="price_title">Free</h3>
    //     <p className="price">$0</p>
    //     <p className="price_description">Capture ideas and find them quickly</p>
    //     <ul className="plan_item-list">
    //       <li>Sync unlimited devices</li>
    //       <li>10 GB monthly uploads</li>
    //       <li>200 MB max. note size</li>
    //       <li>Customize Home dashboard and access extra widgets</li>
    //       <li>Connect primary Google Calendar account</li>
    //       <li>Add due dates, reminders, and notifications to your tasks</li>
    //     </ul>
    //     <MyButton text={"Get Started"} href={"#"} type={"no-arrow"}></MyButton>
    //   </article>

      
    // </div>
  );
}

export default PriceCard;