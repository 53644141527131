import React from 'react';
import './App.css';
import MyButton from './components/Button/Button';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Kv from './components/Kv/Kv';
import Heading from './components/Heading/Heading';
import PriceCard from './components/PriceCard/PriceCard';
import ClientSlider from './components/ClientSlider/ClientSlider';
import { useInView } from "react-intersection-observer";


function App() {
  // fadein関数のtargets
  // const targets = document.querySelectorAll(".js-fadeInStart");
  // console.log(targets);
  // let observe = new IntersectionObserver(callback);
  // targets.forEach(function(value){
  //   observe.observe(value);
  // })
  // const { ref, inView } = useInView({
  //   rootMargin: "100px",
  //   triggerOnce: true,
  // });
  // コールバック関数
  // function callback(entries) {
  //   if(entries[0].isIntersecting) {
  //     entries[0].target.classList.add("js-fadeIn");
  //     entries[0].target.classList.remove("js-fadeInStart");
  //   } 
  // }
  return (
    <>
      <Header></Header>
      <Kv></Kv>
      <section className='section bg02' id='products'>
        <div className='content_inner'>
          <div className="l_container-wrap">
            <div className="l_container">
              <div className='l_container-left u-spTextCenter'>
                <Heading headingTitle={"Project "} underLined={"Management"}></Heading>
                <p className='u-mb60'>Images, videos, PDFs and audio files are supported. Create math expressions and diagrams directly from the app. Take photos with the mobile app and save them to a note.</p>
                <MyButton text={"Get Started"} href={"#"}></MyButton>
              </div>
              <div className='l_container-right'>
                <img src="./img/img02.png" alt="" />
              </div>
            </div>
          </div>
          <div className='l_container-wrap'>
              <div className='l_container-reverse'>
                <div className='l_container-left u-spTextCenter'>
                  <Heading headingTitle={"Work "} underLined={"together"}></Heading>
                  <p className='u-mb60'>Images, videos, PDFs and audio files are supported. Create math expressions and diagrams directly from the app. Take photos with the mobile app and save them to a note.</p>
                  <MyButton text={"Try it now"} href={"#"}></MyButton>
                </div>
                <div className='l_container-right'>
                  <img src="./img/img03.png" alt="" />
                </div>
              </div>
          </div>
        </div>
      </section>
      <section className='bg_navy section' id='solutions'>
        <div className='content_inner'>
          <div className='l_container-wrap'>
            <div className='l_container'>
              <div className='l_container-left u-spTextCenter'>
                <Heading headingTitle={"Use as "} underLined={"Extension"}></Heading>
                <p className='u-mb60'>Use the web clipper extension, available on Chrome and Firefox, to save web pages or take screenshots as notes.</p>
                <MyButton text={"Let's go"} href={"#"}></MyButton>
              </div>
              <div className='l_container-right'>
                <img src="./img/img04.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='content_inner'>
          <div className='l_container-wrap'>
            <div className='l_container-reverse'>
              <div className='l_container-left u-spTextCenter'>
                <Heading headingTitle={"Customise it to "} underLined={"your needs"}></Heading>
                <p className='u-mb60'>Customise the app with plugins, custom themes and multiple text editors (Rich Text or Markdown). Or create your own scripts and plugins using the Extension API.</p>
                <MyButton text={"Let's go"} href={"#"}></MyButton>
              </div>
              <div className='l_container-right'>
                <img src="./img/img07.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section' id='pricing'>
        <div className='content_inner u-spTextCenter scroll-container'>
          <Heading headingTitle={"Choose "} underLined={"Your plan"} position={"center"}></Heading>
          <p>Whether you want to get organized, keep your personal life on track, or boost workplace productivity, Evernote has the right plan for you.</p>
          <PriceCard></PriceCard>
        </div>
      </section>
      <section className='section bg_navy bg03' id='Resources'>
        <div className='content_inner u-spTextCenter'>
          <Heading headingTitle={"Your work everywhere "} underLined={"you are"} position={"center"} color={"blue"}></Heading>
          <p className='u-mb60'>Access your notes from your computer, phone or tablet by synchronising with various services, including whitepace, Dropbox and OneDrive. The app is available on Windows, macOS, Linux, Android and iOS. A terminal app is also available!</p>
          <div className='u-textCenter'>
            <MyButton text={"try taskie"}></MyButton>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='content_inner'>
          <div className='l_container-wrap'>
            <div className='l_container'>
              <div className='l_container-left u-spTextCenter'>
                <Heading headingTitle={"100% "} underLined={"your data"}></Heading>
                <p className='u-mb60'>The app is open source and your notes are saved to an open format, so you'll always have access to them. Uses End-To-End Encryption (E2EE) to secure your notes and ensure no-one but yourself can access them.</p>
                <MyButton text={"Read more"} href={"#"}></MyButton>
              </div>
              <div className='l_container-right'>
                <img src="./img/img05.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='content_inner'>
          <Heading headingTitle={"Our "} underLined={"sponsors"} position={"center"}></Heading>
          <ul className='l_container sponsor_container'>
            <li><img src="./img/apple.png" alt="Apple" /></li>
            <li><img src="./img/microsoft.png" alt="Microsoft" /></li>
            <li><img src="./img/slack.png" alt="Slack" /></li>
            <li><img src="./img/google.png" alt="Google" /></li>
          </ul>
        </div>
      </section>
      <section className='bg_navy section bg04'>
        <div className='content_inner'>
          <div className='l_container-wrap'>
            <div className='l_container-reverse'>
              <div className='l_container-left u-spTextCenter'>
                <Heading headingTitle={"Work with Your Favorite Apps Using whitepace"}></Heading>
                <p className='u-mb60'>Whitepace teams up with your favorite software. Integrate with over 1000+ apps with Zapier to have all the tools you need for your project success.</p>
                <MyButton text={"Read more"} href={"#"}></MyButton>
              </div>
              <div className='l_container-right'>
                <img src="./img/img06.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='content_inner'>
          <Heading headingTitle={"What Our Clients "} underLined={"Says"} position={"center"}></Heading>
          <ClientSlider></ClientSlider>
        </div>
      </section>
       <section className='section bg_navy'>
        <div className='content_inner'>
          <Heading headingTitle={"Try Whitepace today "}position={"center"} color={"blue"}></Heading>
          <p className='u-textCenter u-mb40'>Get started for free.Add your whole team as your needs grow.</p>
          <div className='u-textCenter u-mb40'>
            <MyButton text={"try taskie"}></MyButton>
          </div>
          <p className='u-textCenter u-mb40'>On a big team? Contact sales</p>
          <ul className='u-flexCenter u-flexColumnGap10'>
            <li><img src="./img/apple_white.png" alt="Apple" /></li>
            <li><img src="./img/microsoft_white.png" alt="Microsoft" /></li>
            <li><img src="./img/slack_white.png" alt="Slack" /></li>
          </ul>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default App;
