import React from "react";
import "./Footer.css";
import MyButton from '../Button/Button';
function Footer({}) {
  return (
    <footer>
      <div className="content_inner">
        <nav className="footer_nav u-spTextCenter">
          <div className="footer_item">
            <img src="./img/logo.png" alt="Whitepace" className="footer_logo"/>
            <p>whitepace was created for the new ways we live and work. We make a better workspace around the world</p>
          </div>
          <div className="footer_item">
            <ul>
              <li><a href="#" className="footer_item-title">Product</a></li>
              <li><a href="#">Overview</a></li>
              <li><a href="#">Priceing</a></li>
              <li><a href="#">Customer stories</a></li>
            </ul>
          </div>
          <div className="footer_item">
            <ul>
              <li><a href="#" className="footer_item-title">Resources</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">Guides & tutorials</a></li>
              <li><a href="#">Help center</a></li>
            </ul>
          </div>
          <div className="footer_item">
            <ul>
              <li><a href="#" className="footer_item-title">Company</a></li>
              <li><a href="#">About us</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Media kit</a></li>
            </ul>
          </div>
          <div className="footer_item">
            <span><a href="#" className="footer_item-title bigger">Try it today</a></span>
            <p className="u-mb20">Get started for free. Add your whole team as your needs grow.</p>
            <MyButton text={"start today"} href={"#"}></MyButton>
          </div>
        </nav>
        <div className="footer_bar u-spTextCenter">
          <div className="footer_left">
            <ul>
              <li>
                <a href=""><img src="" alt="" />English</a>
              </li>
              <li>
                <a href="">Terms & privacy</a>
              </li>
              <li>
                <a href="">Security</a>
              </li>
              <li>
                <a href="">Status</a>
              </li>
              <li>
                &copy;20221 Whitepace LLC.
              </li>
            </ul>
          </div>
          <div className="footer_right">
            <ul>
              <li><a href=""><img src="./img/facebook.png" alt="" /></a></li>
              <li><a href=""><img src="./img/twitter.png" alt="" /></a></li>
              <li><a href=""><img src="./img/linkedin.png" alt="" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;