import React from "react";
import { Navigation, Pagination, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// Customized ClientSlider styles
import "./ClientSlider.css";

function ClientSlider({}) {
  return (
       <Swiper
        modules={[Navigation,Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={50}
        slidesPerView={3}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          // when window width is >= 768px
          0: {
            slidesPerView: 1,
          },
          // when window width is >= 640px
          768: {
            slidesPerView: 3,
          },
      
        }}
      >
        <SwiperSlide>
          <div className="slide_card">
            <img src="./img/quote.png" alt="”" className="client_quote" />
            <p className="client_comment">Whitepate is designed as a collaboration tool for businesses that is a full project management solution.</p>
            <div className="client_container">
              <img src="./img/client01.png" alt="profile" className="client_picture" />
              <div className="client_profile-wrap">
                <strong className="client_name">Oberon Shaw, MCH</strong>
                <p className="client_description">Head of Talent Acquisition, North America</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide_card blue">
            <img src="./img/quote_white.png" alt="”" className="client_quote" />
            <p className="client_comment">Whitepate is designed as a collaboration tool for businesses that is a full project management solution.</p>
            <div className="client_container">
              <img src="./img/client02.png" alt="profile" className="client_picture" />
              <div className="client_profile-wrap">
                <strong className="client_name">Oberon Shaw, MCH</strong>
                <p className="client_description">Head of Talent Acquisition, North America</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide_card">
            <img src="./img/quote.png" alt="”" className="client_quote" />
            <p className="client_comment">Whitepate is designed as a collaboration tool for businesses that is a full project management solution.</p>
            <div className="client_container">
              <img src="./img/client03.png" alt="profile" className="client_picture" />
              <div className="client_profile-wrap">
                <strong className="client_name">Oberon Shaw, MCH</strong>
                <p className="client_description">Head of Talent Acquisition, North America</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide_card">
            <img src="./img/quote.png" alt="”" className="client_quote" />
            <p className="client_comment">Whitepate is designed as a collaboration tool for businesses that is a full project management solution.</p>
            <div className="client_container">
              <img src="./img/client02.png" alt="profile" className="client_picture" />
              <div className="client_profile-wrap">
                <strong className="client_name">Oberon Shaw, MCH</strong>
                <p className="client_description">Head of Talent Acquisition, North America</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide_card">
            <img src="./img/quote.png" alt="”" className="client_quote" />
            <p className="client_comment">Whitepate is designed as a collaboration tool for businesses that is a full project management solution.</p>
            <div className="client_container">
              <img src="./img/client03.png" alt="profile" className="client_picture" />
              <div className="client_profile-wrap">
                <strong className="client_name">Oberon Shaw, MCH</strong>
                <p className="client_description">Head of Talent Acquisition, North America</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
  );
}

export default ClientSlider;