import React from "react";
import "./Header.css";
import Navigation from "../Navigation/Navigation";
function Header({}) {
  return (
    <header>
      <div className="header_inner">
        <div className="header-left">
          <a href="/">
            <img src="./img/logo.png" alt="logo" width="191" height="34"/>
          </a>
        </div>
        <Navigation></Navigation>
      </div>
    </header>
  );
}

export default Header;